import { createSlice } from '@reduxjs/toolkit';
import { fetchOrganization } from './organization.slice';
import { fetchRootUsers } from './user.slice';
import { fetchAssignablePermissions } from './assignablePermission.slice';
import { parseCustomerSignature } from 'utils/customerSignature';
import api from '../services/api.service';
const initialState = {
    loading: true,
    hasErrors: false,
    user: null,
    myUser: null
};
const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        onLoginSuccess: (state, _ref) => {
            let { payload: user } = _ref;
            if (!user) {
                /**
                 * If we don't have a user, this should be considered an error
                 * and we'll consequently set the redux state to mimic this
                 */
                state.hasErrors = true;
                state.user = null;
                state.myUser = null;
            }
            else {
                state.hasErrors = false;
                state.user = user;
            }
            state.loading = false;
        },
        onLogout: state => {
            localStorage.clear();
            state.hasErrors = false;
            state.loading = false;
            state.user = null;
            state.myUser = null;
        },
        onFetchTEUserSuccess: (state, _ref2) => {
            let { payload: teUser } = _ref2;
            state.myUser = teUser;
        }
    }
});
export default slice.reducer;
// Selectors
export const userSelector = state => state.auth.user;
/** Use only for URL & navigation related purposes */
export const parsedCustomerSignatureSelector = state => parseCustomerSignature(state.auth.user.customerSignature);
export const myUserSelector = state => state.auth.myUser;
// Actions
export const { onLoginSuccess, onLogout, onFetchTEUserSuccess } = slice.actions;
export const fetchTEUserFromToken = token => async (dispatch) => {
    try {
        const endpoint = token.scopes.includes('TE_ROOT_USER') ? `/users/root/${token.id}` : `/users/${token.id}`;
        const response = await api.get({
            endpoint,
            suppressNotification: true
        });
        dispatch(onFetchTEUserSuccess(response));
    }
    catch (error) {
        console.error(error);
    }
};
export const fetchInitialData = () => dispatch => {
    dispatch(fetchOrganization());
    dispatch(fetchRootUsers());
    dispatch(fetchAssignablePermissions());
};
