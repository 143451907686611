import { createSlice } from '@reduxjs/toolkit';
import { chunk, cloneDeep, compact, keyBy, merge, get } from 'lodash';
import { startAppListening } from 'slices/listenerMiddleware';
import api from '../services/api.service';
import { addEntityToState, deleteEntityFromState, finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, prepEntityForAPICall, upsertEntity } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
import { createUser as createTEUser } from '@timeedit/types';
import { registerReloadWarning, unregisterReloadWarning } from 'hooks/useReloadWarning';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { validateObjectId } from 'utils/objectId.validation';
export const MAX_RESULTS = 200;
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    totalResults: 0,
    isFirstUserSaveDisabled: true,
    isSecondUserSaveDisabled: true,
    firstSelectedUser: undefined,
    secondSelectedUser: undefined,
    rootUserMap: {},
    downloadUsersPayload: {
        filter: {}
    },
    findUserPayload: {
        limit: MAX_RESULTS,
        page: 1,
        filter: {}
    },
    selectedUserIds: [],
    selectedUsers: [],
    importProgress: -1,
    importError: null,
    totalUsers: 0,
    shouldReload: false
};
const DEFAULT_LIMIT = 50;
export const exportUsers = async (options) => {
    return await api.post({
        endpoint: '/users/export/csv',
        responseType: 'blob',
        suppressNotification: true,
        data: createFindManyTEUsersBodyWithoutEmptyValues(options)
    });
};
export const fetchManyUsers = async (options) => {
    return await api.post({
        endpoint: '/users/find-many',
        suppressNotification: true,
        data: createFindManyTEUsersBodyWithoutEmptyValues({
            ...options,
            limit: options.limit || DEFAULT_LIMIT
        })
    });
};
function createFindManyTEUsersBodyWithoutEmptyValues(options) {
    return Object.entries(options).reduce((acc, _ref) => {
        let [key, value] = _ref;
        if (value) {
            acc[key] = value;
        }
        return acc;
    }, {});
}
export const fetchMoreUsers = createAppAsyncThunk('users/fetchMoreUsers', async (options, _ref2) => {
    let { dispatch } = _ref2;
    dispatch(fetchUsersRequest());
    const response = await fetchManyUsers(options);
    dispatch(fetchMoreUsersSuccess(response.results));
    return response;
});
const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        fetchUsersRequest: state => {
            isLoadingRequest(state);
        },
        fetchUsersSuccess: (state, _ref3) => {
            let { payload: users } = _ref3;
            state.results = users;
            state.map = keyBy(users, 'id');
            finishedLoadingSuccess(state);
        },
        fetchRootUsersSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            state.rootUserMap = keyBy(payload, 'id');
            finishedLoadingSuccess(state);
        },
        fetchMoreUsersSuccess: (state, _ref5) => {
            let { payload: users } = _ref5;
            state.results = state.results.concat(users);
            state.map = merge({}, state.map, keyBy(users, 'id'));
            finishedLoadingSuccess(state);
        },
        fetchUsersByIdSuccess: (state, _ref6) => {
            let { payload } = _ref6;
            for (const userResult of payload) {
                const { isLegacyUser, user } = userResult;
                if (isLegacyUser) {
                    state.map[user.legacyId] = user;
                }
                state.map[user.id] = user;
            }
            finishedLoadingSuccess(state);
        },
        fetchUsersFailure: state => {
            finishedLoadingFailure(state);
        },
        createUnsavedUser: state => {
            state.firstSelectedUser = addEntityToState(state, {
                id: 'new',
                firstName: 'Firstname',
                lastName: 'Lastname',
                scopes: [],
                appPermissions: [],
                organizationsAndRoles: [],
                userObjects: [],
                profileName: 'Default'
            }, createTEUser);
            finishedLoadingSuccess(state);
        },
        duplicateUser: (state, _ref7) => {
            let { payload } = _ref7;
            state.firstSelectedUser = addEntityToState(state, {
                ...payload,
                id: 'new',
                lastName: payload.lastName + '-copy',
                profiles: payload.profiles?.map(_ref8 => {
                    let { id, ...profile } = _ref8;
                    return {
                        id: `new-profile-${id}-${Date.now()}`,
                        ...profile
                    };
                }),
                history: [],
                logins: []
            }, createTEUser);
            finishedLoadingSuccess(state);
        },
        changeUser: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, key, value } = payload;
            if (isFirstDrawer) {
                state.firstSelectedUser = {
                    ...cloneDeep(state.firstSelectedUser),
                    [key]: value
                };
            }
            else {
                state.secondSelectedUser = {
                    ...cloneDeep(state.secondSelectedUser),
                    [key]: value
                };
            }
        },
        saveUserRequest: state => {
            isLoadingRequest(state);
        },
        saveUserSuccess: (state, _ref10) => {
            let { payload: { user } } = _ref10;
            upsertEntity(state, user, createTEUser);
            if (state.firstSelectedUser?.id === user.id || state.firstSelectedUser?.id === 'new') {
                state.firstSelectedUser = undefined;
            }
            else {
                state.secondSelectedUser = undefined;
            }
            state.map[user.id] = user;
            state.results = state.results.map(u => u.id === user.id ? user : u);
            state.selectedUsers = state.selectedUsers.map(u => u.id === user.id ? user : u);
            finishedLoadingSuccess(state);
        },
        saveUserFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteUserRequest: state => {
            isLoadingRequest(state);
        },
        deleteUserSuccess: (state, _ref11) => {
            let { payload: { userId } } = _ref11;
            deleteEntityFromState(userId, state);
            if (state.firstSelectedUser?.id === userId) {
                state.firstSelectedUser = undefined;
            }
            else {
                state.secondSelectedUser = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deleteUserFailure: state => {
            finishedLoadingFailure(state);
        },
        resetPasswordForUserRequest: state => {
            isLoadingRequest(state);
        },
        resetPasswordForUserSuccess: state => {
            finishedLoadingSuccess(state);
        },
        resetPasswordForUserFailure: state => {
            finishedLoadingFailure(state);
        },
        setIsUserDisabled: (state, _ref12) => {
            let { payload: { isFirstDrawer, user } } = _ref12;
            const property = isFirstDrawer ? 'isFirstUserSaveDisabled' : 'isSecondUserSaveDisabled';
            if (!user) {
                state[property] = true;
                return;
            }
            state[property] = checkSavedDisabled(user, state.map[user.id]);
        },
        setFirstSelectedUser: (state, _ref13) => {
            let { payload: user } = _ref13;
            const prevUser = state.firstSelectedUser;
            state.firstSelectedUser = user;
            if (prevUser?.id === 'new') {
                state.results = state.results.filter(u => u.id !== 'new');
                state.map.new = undefined;
            }
        },
        setSecondSelectedUser: (state, _ref14) => {
            let { payload: user } = _ref14;
            state.secondSelectedUser = user;
        },
        setSelectedUserIds: (state, _ref15) => {
            let { payload: ids } = _ref15;
            state.selectedUserIds = ids;
        },
        setSelectedUsers: (state, _ref16) => {
            let { payload } = _ref16;
            state.selectedUsers = payload;
        },
        setIsImportingUsers: (state, _ref17) => {
            let { payload } = _ref17;
            state.importProgress = payload;
        },
        setIsImportingUsersError: (state, _ref18) => {
            let { payload } = _ref18;
            state.importError = payload;
        },
        setDownloadUsersPayload: (state, _ref19) => {
            let { payload } = _ref19;
            state.downloadUsersPayload = payload;
        },
        setFindUserPayload: (state, _ref20) => {
            let { payload } = _ref20;
            state.findUserPayload = payload;
        },
        fetchTotalUsersSuccess: (state, _ref21) => {
            let { payload } = _ref21;
            state.totalUsers = payload;
        },
        triggerReload: state => {
            state.shouldReload = !state.shouldReload;
        }
    },
    selectors: {
        isFirstUserSaveDisabledSelector: state => state.isFirstUserSaveDisabled,
        isSecondUserSaveDisabledSelector: state => state.isSecondUserSaveDisabled,
        selectedUserIdsSelector: state => state.selectedUserIds
    }
});
export default slice.reducer;
// Selectors
export const usersSelector = state => state.users.results;
export const userSelector = id => state => id ? state.users.map[id] : undefined;
export const usersMapSelector = state => state.users.map;
export const firstSelectedUserSelector = state => state.users.firstSelectedUser;
export const secondSelectedUserSelector = state => state.users.secondSelectedUser;
export const allUserMapSelector = state => ({
    ...state.users.rootUserMap,
    ...state.users.map
});
export const selectedUsersSelector = state => state.users.selectedUsers;
export const importProgressSelector = state => state.users.importProgress;
export const importErrorSelector = state => state.users.importError;
export const selectDownloadUsersPayload = state => state.users.downloadUsersPayload;
export const totalUsersSelector = state => state.users.totalUsers;
export const shouldReloadSelector = state => state.users.shouldReload;
// Actions
export const { fetchUsersRequest, fetchUsersSuccess, fetchMoreUsersSuccess, fetchUsersFailure, createUnsavedUser, deleteUserRequest, deleteUserFailure, deleteUserSuccess, saveUserRequest, saveUserSuccess, saveUserFailure, resetPasswordForUserRequest, resetPasswordForUserSuccess, resetPasswordForUserFailure, setFirstSelectedUser, setSecondSelectedUser, duplicateUser, fetchRootUsersSuccess, setIsImportingUsers, setIsUserDisabled, setSelectedUserIds, setSelectedUsers, changeUser, fetchUsersByIdSuccess, setDownloadUsersPayload, setFindUserPayload, setIsImportingUsersError, fetchTotalUsersSuccess, triggerReload } = slice.actions;
export const { isFirstUserSaveDisabledSelector, isSecondUserSaveDisabledSelector, selectedUserIdsSelector } = slice.selectors;
export const fetchUserById = userId => async (dispatch) => {
    if (!userId) {
        return;
    }
    dispatch(fetchUsersByIds([userId]));
};
export const fetchUsersByIds = userIds => async (dispatch) => {
    if (!userIds.length) {
        return;
    }
    try {
        dispatch(fetchUsersRequest());
        const userResultPromises = [];
        for (const userId of userIds) {
            const isLegacyUser = !validateObjectId(userId);
            const endpoint = !isLegacyUser ? `/users/${userId}` : `/users/legacy/${userId}`;
            userResultPromises.push(api.get({
                endpoint,
                suppressNotification: true
            }).then(user => ({
                user,
                isLegacyUser
            })));
        }
        const results = (await Promise.allSettled(userResultPromises)).filter(promise => promise.status === 'fulfilled').map(promise => promise.value);
        dispatch(fetchUsersByIdSuccess(results));
    }
    catch (e) {
        dispatch(fetchUsersFailure());
    }
};
export const saveUser = createAppAsyncThunk('users/saveUser', async (user, _ref22) => {
    let { dispatch } = _ref22;
    try {
        dispatch(saveUserRequest());
        const userPayload = {
            ...user,
            profiles: user.profiles?.map(profile => {
                if (!profile.id.includes('new-profile')) {
                    return profile;
                }
                const { id, ...rest } = profile;
                return rest;
            })
        };
        if (user.id === 'new') {
            // Create a new one
            const { legacyId, ...rest } = userPayload;
            const preppedUser = prepEntityForAPICall(rest, true, true);
            const savedUser = await api.post({
                endpoint: `/users`,
                data: preppedUser,
                successMessage: 'Successfully created user'
            });
            dispatch(saveUserSuccess({
                user: savedUser
            }));
            dispatch(deleteUserSuccess({
                userId: 'new'
            }));
            dispatch(fetchTotalUsers());
            dispatch(triggerReload());
        }
        else {
            const preppedUser = prepEntityForAPICall(userPayload, true, true);
            const updatedUser = await api.patch({
                endpoint: `/users/${user.id}`,
                data: preppedUser
            });
            dispatch(saveUserSuccess({
                user: updatedUser
            }));
        }
    }
    catch (error) {
        dispatch(saveUserFailure());
        console.error(error);
    }
});
export const deleteUser = userId => async (dispatch) => {
    try {
        dispatch(deleteUserRequest());
        /**
         * No need to send the request to the backend if the entity hasn't been saved
         */
        if (userId !== 'new')
            await api.delete({
                endpoint: `/users/${userId}`
            });
        dispatch(deleteUserSuccess({
            userId
        }));
        dispatch(fetchTotalUsers());
        dispatch(triggerReload());
    }
    catch (e) {
        dispatch(deleteUserFailure());
        console.error(e);
    }
};
export const resetPasswordForUser = userId => async (dispatch) => {
    try {
        if (userId) {
            dispatch(resetPasswordForUserRequest());
            await api.post({
                endpoint: `/users/${userId}/reset-password`,
                successMessage: 'An email with a link to reset their password has been emailed to the user'
            });
            dispatch(resetPasswordForUserSuccess());
        }
    }
    catch (e) {
        dispatch(resetPasswordForUserFailure());
        console.error(e);
    }
};
export const importUsers = (authConfigId, users) => async (dispatch, getState) => {
    const handleTabClose = registerReloadWarning();
    dispatch(setIsImportingUsersError(null));
    try {
        dispatch(setIsImportingUsers(0));
        const chunkUsers = chunk(users, 50);
        let hasError = false;
        for (let i = 0; i < chunkUsers.length; i++) {
            try {
                await api.post({
                    endpoint: `/users/import`,
                    data: {
                        authConfigId,
                        users: chunkUsers[i]
                    },
                    suppressNotification: true
                });
                dispatch(setIsImportingUsers(Math.floor((i + 1) * 100 / chunkUsers.length)));
            }
            catch (e) {
                console.error('errors_users/import', e);
                dispatch(setIsImportingUsersError({
                    message: get(e, 'response.data.message', 'Error importing users')
                }));
                hasError = true;
                break;
            }
        }
        if (!hasError) {
            const { findUserPayload } = getState().users;
            const response = await fetchManyUsers({
                ...findUserPayload,
                limit: MAX_RESULTS,
                page: 1
            });
            dispatch(fetchUsersSuccess(response.results));
            dispatch(setIsImportingUsers(100));
        }
    }
    catch (e) {
        console.error(e);
    }
    finally {
        unregisterReloadWarning(handleTabClose);
    }
};
export const fetchRootUsers = createAppAsyncThunk('users/fetchRootUsers', async (_, _ref23) => {
    let { dispatch } = _ref23;
    try {
        dispatch(fetchUsersRequest());
        const result = await api.get({
            endpoint: `/users/root/info`
        });
        dispatch(fetchRootUsersSuccess(result.results || []));
    }
    catch (e) {
        dispatch(fetchUsersFailure());
    }
});
export const fetchTotalUsers = createAppAsyncThunk('users/fetchTotalUsers', async (_, _ref24) => {
    let { dispatch } = _ref24;
    try {
        dispatch(fetchUsersRequest());
        const { totalResults } = await fetchManyUsers({
            filter: {},
            page: 1,
            limit: 1
        });
        dispatch(fetchTotalUsersSuccess(totalResults));
    }
    catch (e) {
        dispatch(fetchUsersFailure());
    }
});
export const checkUserSaveDisabled = isFirstDrawer => (_, getState) => {
    const { users } = getState();
    return isFirstDrawer ? users.isFirstUserSaveDisabled : users.isSecondUserSaveDisabled;
};
export const checkFirstUserExists = () => (_, getState) => !!getState().users.firstSelectedUser;
startAppListening({
    predicate: (_, currentState, previousState) => currentState.users.firstSelectedUser?.id !== previousState.users.firstSelectedUser?.id || currentState.users.secondSelectedUser?.id !== previousState.users.secondSelectedUser?.id,
    effect: (_, _ref25) => {
        let { dispatch, getState } = _ref25;
        const { users } = getState();
        dispatch(setSelectedUserIds(compact([users.firstSelectedUser?.id, users.secondSelectedUser?.id])));
    }
});
const selectedUserPredicate = key => (_, currentState, previousState) => currentState.users[key] !== previousState.users[key];
const selectedUserEffect = key => (_, _ref26) => {
    let { dispatch, getState } = _ref26;
    dispatch(setIsUserDisabled({
        isFirstDrawer: key === 'firstSelectedUser',
        user: getState().users[key]
    }));
};
startAppListening({
    predicate: selectedUserPredicate('firstSelectedUser'),
    effect: selectedUserEffect('firstSelectedUser')
});
startAppListening({
    predicate: selectedUserPredicate('secondSelectedUser'),
    effect: selectedUserEffect('secondSelectedUser')
});
