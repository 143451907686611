import { createSlice } from '@reduxjs/toolkit';
import { current } from 'immer';
import api from '../services/api.service';
import { isEmpty, isNumber } from 'lodash';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest, stateHasFetchedAllObjectsSuccess } from 'utils/sliceHelpers';
import { configService } from 'services/config.service';
import { buildTypeTree, convertDataTypesForm2BodyCreate, convertDataTypesForm2BodyUpdate, createTypesLocal, isNewObjTypeId, TEMP_ID } from 'utils/objectTypes';
import { getAllData } from 'utils/fetchAllData';
export const initialState = {
    loading: false,
    hasErrors: false,
    results: [],
    map: {},
    page: 0,
    limit: 10000,
    totalPages: 0,
    totalResults: 0,
    firstDrawer: null,
    secondDrawer: null,
    externalOwners: [],
    typeTree: {}
};
// Slice
const slice = createSlice({
    name: 'objectTypes',
    initialState,
    reducers: {
        // =============== get list of object types ===============
        fetchObjectMapping: state => {
            isLoadingRequest(state);
        },
        fetchObjectTypesSuccess: (state, _ref) => {
            let { payload } = _ref;
            stateHasFetchedAllObjectsSuccess(payload, state, i => i);
            state.typeTree = buildTypeTree(state.results);
            finishedLoadingSuccess(state);
        },
        fetchObjectMappingFailure: state => {
            finishedLoadingFailure(state);
        },
        // ========= get detail of object type ===============
        fetchObjectTypesDetailSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            const { isFirstDrawer, data, advanced } = payload;
            state.map[data.id] = data;
            if (isFirstDrawer) {
                if (advanced && state.firstDrawer) {
                    state.firstDrawer.advancedSettings = advanced;
                }
            }
            else {
                if (advanced && state.secondDrawer) {
                    state.secondDrawer.advancedSettings = advanced;
                }
            }
            finishedLoadingSuccess(state);
        },
        fetchObjectTypesDetail: (state, _ref3) => {
            let { payload } = _ref3;
            const { isFirstDrawer, id, record } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = record || state.map[id];
            }
            else {
                state.secondDrawer = record || state.map[id];
            }
            if (!record) {
                removeLocalObjectType(state);
            }
        },
        fetchObjectTypesDetailFailure: state => {
            finishedLoadingSuccess(state);
        },
        // =============== update object types local ===============
        updateDrawerObjectType: (state, _ref4) => {
            let { payload } = _ref4;
            const { isFirstDrawer, data } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = data;
            }
            else {
                state.secondDrawer = data;
            }
            if (!data) {
                removeLocalObjectType(state);
            }
        },
        // ============= request update object types ===============
        updateObjectTypesDetailSuccess(state, _ref5) {
            let { payload } = _ref5;
            const { data, isFirstDrawer } = payload;
            if (isFirstDrawer && state?.firstDrawer) {
                state.firstDrawer = null;
            }
            if (!isFirstDrawer && state?.secondDrawer) {
                state.secondDrawer = null;
            }
            state.map[data.id] = data;
            const dataCurrent = current(state.results);
            const index = dataCurrent.findIndex(i => i.id === data.id);
            if (index !== -1) {
                state.results[index] = data;
            }
            finishedLoadingSuccess(state);
        },
        // ========= delete object type ===============
        deleteObjectType: state => {
            isLoadingRequest(state);
        },
        deleteObjectTypeSuccess: state => {
            finishedLoadingSuccess(state);
        },
        deleteObjectTypeFailure: state => {
            finishedLoadingFailure(state);
        },
        // =============== create object type ===============
        addNewObjectTypeLocal: (state, _ref6) => {
            let { payload } = _ref6;
            const newObjType = createTypesLocal(payload);
            if (state.map[newObjType.id]) {
                state.firstDrawer = state.map[newObjType.id];
                return;
            }
            state.map[newObjType.id] = newObjType;
            if (payload) {
                const index = state.results.findIndex(type => type.id === payload.id);
                const parent = state.results[index];
                if (parent) {
                    const newSubTypes = [newObjType.id, ...(parent.subTypes || [])];
                    state.results[index].subTypes = newSubTypes;
                    state.map[parent.id].subTypes = newSubTypes.slice();
                }
            }
            state.firstDrawer = newObjType;
            state.results = [newObjType, ...state.results];
        },
        requestCreateObjectType(state) {
            isLoadingRequest(state);
        },
        createObjectTypeFailure: state => {
            finishedLoadingFailure(state);
        },
        deleteLocalObjectType: (state, _ref7) => {
            let { payload } = _ref7;
            state.results = state.results.filter(item => {
                return !payload.includes(item.id);
            });
            payload.forEach(id => {
                if (state.map[id]) {
                    const parentFields = state.map[id]?.parentFields;
                    if (parentFields && parentFields.length) {
                        const index = state.results.findIndex(x => x.id === parentFields[parentFields.length - 1]);
                        if (index !== -1) {
                            state.results[index].subTypes = state.results[index].subTypes.filter(i => i !== id);
                        }
                    }
                    state.results = state.results.filter(x => x.id !== id);
                    state.map[id] = null;
                }
            });
            if (isNewObjTypeId(state?.firstDrawer?.id)) {
                state.firstDrawer = null;
            }
        },
        // ExternalOwners
        fetchExternalOwnersSuccess: (state, _ref8) => {
            let { payload } = _ref8;
            if (payload)
                state.externalOwners = payload;
        },
        fetchExternalOwnersFailure: state => {
            finishedLoadingFailure(state);
        },
        // onDiscardForm
        onDiscardForm: (state, _ref9) => {
            let { payload } = _ref9;
            const { isFirstDrawer, id } = payload;
            if (isFirstDrawer) {
                state.firstDrawer = state.map[id];
            }
            else {
                state.secondDrawer = state.map[id];
            }
        }
    }
});
const assertLocalObjectType = type => type !== undefined && 'parentIds' in type;
function removeLocalObjectType(state) {
    const index = state.results.findIndex(type => type.id === TEMP_ID);
    const [localObjectType] = state.results.splice(index, 1);
    if (!assertLocalObjectType(localObjectType)) {
        return;
    }
    delete state.map[TEMP_ID];
    const parentId = localObjectType.parentIds.at(-1);
    const parentIndex = state.results.findIndex(type => type.id === parentId);
    const parent = state.results[parentIndex];
    if (!parent) {
        return;
    }
    const newSubTypes = parent.subTypes.filter(id => id !== TEMP_ID);
    parent.subTypes = newSubTypes;
    state.map[parentId].subTypes = newSubTypes.slice();
}
export default slice.reducer;
// Selectors
export const selectObjectTypesLoading = state => state.objectTypes.loading;
export const selectObjectTypesMap = state => state.objectTypes.map;
export const selectObjectTypesTree = state => state.objectTypes.typeTree;
export const selectFirstDrawer = state => state.objectTypes.firstDrawer;
export const selectSecondDrawer = state => state.objectTypes.secondDrawer;
export const selectObjectTypesList = state => state.objectTypes.results;
export const selectExternalOwners = state => state.objectTypes.externalOwners;
// Actions
export const { 
// get list of object types
fetchObjectMapping, fetchObjectTypesSuccess, fetchObjectMappingFailure, 
// detail of object types
fetchObjectTypesDetailSuccess, fetchObjectTypesDetail, fetchObjectTypesDetailFailure, updateDrawerObjectType, updateObjectTypesDetailSuccess, 
// del obj types
deleteObjectType, deleteObjectTypeSuccess, deleteObjectTypeFailure, deleteLocalObjectType, 
// add new
addNewObjectTypeLocal, requestCreateObjectType, createObjectTypeFailure, 
// master data
fetchExternalOwnersSuccess, fetchExternalOwnersFailure, 
//
onDiscardForm } = slice.actions;
export const requestExternalOwners = () => async (dispatch) => {
    try {
        const externalOwners = await api.get({
            endpoint: `/types/external-owners`
        });
        if (externalOwners.results) {
            dispatch(fetchExternalOwnersSuccess(externalOwners.results.map(i => ({
                value: i.id,
                label: i.name
            }))));
        }
    }
    catch (e) {
        dispatch(fetchExternalOwnersFailure());
    }
};
export const requestObjectTypes = () => async (dispatch) => {
    try {
        dispatch(fetchObjectMapping());
        const result = await getAllData({
            endpoint: `${configService.REACT_APP_BASE_URL}/types/find`,
            successMessage: false
        });
        dispatch(fetchObjectTypesSuccess(result));
    }
    catch (e) {
        dispatch(fetchObjectMappingFailure());
    }
};
export const requestMoveObjectType = (recordId, destinationId) => async (dispatch, getState) => {
    try {
        const allState = getState();
        const parentData = allState.objectTypes.map[destinationId]?.subTypes?.filter(id => id !== TEMP_ID);
        await api.patch({
            endpoint: `${configService.REACT_APP_BASE_URL}/types/${destinationId}`,
            data: {
                subTypes: [...(parentData || []), recordId]
            }
        });
        dispatch(requestObjectTypes());
    }
    catch (e) {
        dispatch(fetchObjectMappingFailure());
    }
};
export const requestObjectTypesDetail = (isFirstDrawer, record) => async (dispatch) => {
    try {
        const { id, isLocalData } = record;
        dispatch(fetchObjectTypesDetail({
            isFirstDrawer,
            id,
            record
        }));
        if (isLocalData) {
            dispatch(fetchObjectTypesDetailFailure());
            return;
        }
        const result = await api.get({
            endpoint: `${configService.REACT_APP_BASE_URL}/types/${id}`
        });
        const types = await api.get({
            endpoint: `/types/${id}/advanced`
        });
        dispatch(updateDrawerObjectType({
            isFirstDrawer,
            data: {
                ...record,
                ...result
            }
        }));
        dispatch(fetchObjectTypesDetailSuccess({
            isFirstDrawer,
            data: {
                ...record,
                ...result
            },
            advanced: types.results
        }));
    }
    catch (e) {
        console.log(e, 'error');
        dispatch(fetchObjectTypesDetailFailure());
    }
};
export const requestUpdateObjectType = _ref10 => {
    let { isFirstDrawer, data } = _ref10;
    return async (dispatch, getState) => {
        try {
            if (data?.id === undefined) {
                throw new Error('No id provided');
            }
            if (data.isLocalData) {
                // create new
                dispatch(requestCreateObjectType());
                const dataBody = convertDataTypesForm2BodyCreate(data);
                const dataNewObj = await api.post({
                    endpoint: `${configService.REACT_APP_BASE_URL}/types`,
                    data: dataBody,
                    successMessage: 'Object type created successfully'
                });
                if (data.parentIds && data.parentIds[0] && dataNewObj.id) {
                    const allState = getState();
                    const parentId = data.parentIds[data.parentIds.length - 1];
                    const parentData = allState.objectTypes.map[parentId]?.subTypes?.filter(id => id !== TEMP_ID);
                    await api.patch({
                        endpoint: `${configService.REACT_APP_BASE_URL}/types/${parentId}`,
                        data: {
                            subTypes: [...(parentData || []), dataNewObj.id]
                        },
                        successMessage: 'Object type was added to parent'
                    });
                }
                dispatch(updateDrawerObjectType({
                    isFirstDrawer: true,
                    data: null
                }));
                dispatch(requestObjectTypes());
            }
            else {
                dispatch(fetchObjectMapping());
                const dataBody = convertDataTypesForm2BodyUpdate(data);
                const result = await api.patch({
                    endpoint: `${configService.REACT_APP_BASE_URL}/types/${data.id}`,
                    data: dataBody
                });
                dispatch(updateObjectTypesDetailSuccess({
                    isFirstDrawer,
                    data: {
                        ...result,
                        isEdited: false
                    }
                }));
            }
        }
        catch (e) {
            dispatch(createObjectTypeFailure());
            dispatch(fetchObjectTypesDetailFailure());
        }
    };
};
export const requestDeleteObjectTypes = objectIds => async (dispatch) => {
    try {
        const listId = objectIds.filter(i => isNumber(i));
        const listLocalId = objectIds.filter(isNewObjTypeId);
        if (!isEmpty(listLocalId)) {
            dispatch(deleteLocalObjectType(listLocalId));
        }
        if (!listId || !listId[0])
            return 1;
        dispatch(deleteObjectType());
        await Promise.all(listId.map(id => api.delete({
            endpoint: `${configService.REACT_APP_BASE_URL}/types/${id}`
        })));
        dispatch(requestObjectTypes());
        dispatch(deleteObjectTypeSuccess());
        return 1;
    }
    catch (e) {
        dispatch(deleteObjectTypeFailure());
        return 0;
    }
};
export const getDrawerData = isFirstDrawer => (dispatch, getState) => {
    try {
        const state = getState();
        const { firstDrawer, secondDrawer } = state.objectTypes;
        return isFirstDrawer ? firstDrawer : secondDrawer;
    }
    catch (e) {
        dispatch(deleteObjectTypeFailure());
        return null;
    }
};
export const getListIdSelected = state => {
    const { firstDrawer, secondDrawer } = state.objectTypes;
    const result = [];
    if (firstDrawer)
        result.push(firstDrawer.id);
    if (secondDrawer)
        result.push(secondDrawer.id);
    return result;
};
