import { createSlice } from '@reduxjs/toolkit';
import { createEmptyReservationTemplate, EOptionalRelation } from '@timeedit/types';
import { keyBy } from 'lodash';
import { createAppAsyncThunk } from 'slices/utils';
import { checkSavedDisabled } from 'utils/isSavedDisabled';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import api from '../services/api.service';
export const initialState = {
    loading: false,
    hasErrors: false,
    firstSelectedReservationTemplate: null,
    secondSelectedReservationTemplate: null,
    allReservationTemplates: [],
    reservationTemplatesForOtherPages: [],
    map: {},
    advancedSettings: {},
    movedReservationsInfo: {},
    movedToTrashResponse: {},
    optionalRelatedTypes: []
};
// Slice
const slice = createSlice({
    name: 'reservationTemplates',
    initialState,
    reducers: {
        fetchReservationTemplatesRequest: state => {
            isLoadingRequest(state);
        },
        fetchReservationTemplatesSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.allReservationTemplates = payload.results;
            state.map = keyBy(payload.results, 'id');
            state.optionalRelatedTypes = payload.results.flatMap(template => template.types?.filter(type => type.optionalRelation === EOptionalRelation.OPTIONAL).map(t => t.type) || []);
            finishedLoadingSuccess(state);
        },
        fetchReservationTemplatesForOthersSuccess: (state, _ref2) => {
            let { payload } = _ref2;
            state.reservationTemplatesForOtherPages = payload.results;
            state.map = keyBy(payload.results, 'id');
        },
        fetchAdvancedSettingsReservationTemplatesSuccess: (state, _ref3) => {
            let { payload } = _ref3;
            state.advancedSettings[payload.templateId] = payload.results;
            finishedLoadingSuccess(state);
        },
        fetchReservationTemplatesFailure: state => {
            finishedLoadingFailure(state);
        },
        createReservationTemplateRequest: state => {
            isLoadingRequest(state);
        },
        createReservationTemplateSuccess: state => {
            state.firstSelectedReservationTemplate = undefined;
            finishedLoadingSuccess(state);
        },
        createUnsavedReservationTemplateSuccess: (state, _ref4) => {
            let { payload } = _ref4;
            if (state.firstSelectedReservationTemplate?.id === 0)
                return;
            state.allReservationTemplates.unshift(payload);
            state.map[0] = payload;
            state.firstSelectedReservationTemplate = payload;
        },
        createReservationTemplateFailure: state => {
            finishedLoadingFailure(state);
        },
        setReservationTemplate: (state, _ref5) => {
            let { payload: { isFirstTemplate, template } } = _ref5;
            if (isFirstTemplate) {
                state.firstSelectedReservationTemplate = template;
            }
            else {
                state.secondSelectedReservationTemplate = template;
            }
            if (template === undefined) {
                state.allReservationTemplates = state.allReservationTemplates.filter(template => template.id !== 0);
                if (state.map[0])
                    state.map[0] = undefined;
            }
            state.movedReservationsInfo = null;
            state.movedToTrashResponse = null;
        },
        deleteReservationTemplateRequest: state => {
            isLoadingRequest(state);
        },
        deleteRowReservationTemplateSuccess: (state, _ref6) => {
            let { payload } = _ref6;
            state.allReservationTemplates = state.allReservationTemplates.filter(template => template.id !== payload.reservationTemplate.id);
            state.map[payload.reservationTemplate.id] = undefined;
            if (payload.reservationTemplate.id === state.firstSelectedReservationTemplate?.id) {
                state.firstSelectedReservationTemplate = undefined;
            }
            if (payload.reservationTemplate.id === state.secondSelectedReservationTemplate?.id) {
                state.secondSelectedReservationTemplate = undefined;
            }
            finishedLoadingSuccess(state);
        },
        deleteReservationTemplateFailure: state => {
            finishedLoadingFailure(state);
        },
        updateReservationTemplateRequest: state => {
            isLoadingRequest(state);
        },
        updateReservationTemplateSuccess: (state, _ref7) => {
            let { payload } = _ref7;
            const newReservationTemplates = [...state.allReservationTemplates];
            const index = state.allReservationTemplates.findIndex(template => template.id === payload.id);
            newReservationTemplates[index] = payload;
            state.allReservationTemplates = newReservationTemplates;
            if (payload.id === state.firstSelectedReservationTemplate?.id) {
                state.firstSelectedReservationTemplate = undefined;
            }
            if (payload.id === state.secondSelectedReservationTemplate?.id) {
                state.secondSelectedReservationTemplate = undefined;
            }
            state.map[payload.id] = payload;
            finishedLoadingSuccess(state);
        },
        moveReservationsSuccess: (state, _ref8) => {
            let { payload } = _ref8;
            state.movedReservationsInfo = payload;
            finishedLoadingSuccess(state);
        },
        moveToTrashSuccess: (state, _ref9) => {
            let { payload } = _ref9;
            state.movedToTrashResponse = payload;
            finishedLoadingSuccess(state);
        },
        updateReservationTemplateFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const reservationTemplatesLoading = state => state.reservationTemplates.loading;
export const reservationTemplatesSelector = state => state.reservationTemplates.allReservationTemplates;
export const reservationTemplatesForOtherPagesSelector = state => state.reservationTemplates.reservationTemplatesForOtherPages;
export const firstSelectedReservationTemplateSelector = state => {
    return state.reservationTemplates.firstSelectedReservationTemplate;
};
export const secondSelectedReservationTemplateSelector = state => state.reservationTemplates.secondSelectedReservationTemplate;
export const advancedReservationTemplatesSelector = template => state => template && state.reservationTemplates.advancedSettings[template.id] || [];
export const movedReservationsInfoSelector = state => state.reservationTemplates.movedReservationsInfo;
export const movedToTrashResponseSelector = state => state.reservationTemplates.movedToTrashResponse;
export const selectedReservationTemplateMap = state => state.reservationTemplates.map;
export const getListIdSelected = state => {
    const firstDrawer = state.reservationTemplates.firstSelectedReservationTemplate;
    const secondDrawer = state.reservationTemplates.secondSelectedReservationTemplate;
    const result = [];
    if (firstDrawer)
        result.push(firstDrawer.id);
    if (secondDrawer)
        result.push(secondDrawer.id);
    return result;
};
export const selectOptionalRelatedTypes = state => state.reservationTemplates.optionalRelatedTypes;
// Actions
export const { fetchReservationTemplatesRequest, fetchReservationTemplatesSuccess, fetchReservationTemplatesForOthersSuccess, fetchAdvancedSettingsReservationTemplatesSuccess, fetchReservationTemplatesFailure, createReservationTemplateRequest, createReservationTemplateSuccess, createUnsavedReservationTemplateSuccess, createReservationTemplateFailure, setReservationTemplate, deleteReservationTemplateRequest, deleteRowReservationTemplateSuccess, deleteReservationTemplateFailure, updateReservationTemplateRequest, updateReservationTemplateSuccess, updateReservationTemplateFailure, moveReservationsSuccess, moveToTrashSuccess } = slice.actions;
export const fetchReservationTemplates = createAppAsyncThunk('reservationTemplates/fetch', async function () {
    let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    let { dispatch } = arguments.length > 1 ? arguments[1] : undefined;
    const { templateType = 'ALL', reservationMode, active = 'ALL', searchText } = payload || {};
    try {
        dispatch(fetchReservationTemplatesRequest());
        const params = {
            ...(templateType && {
                templateType
            }),
            ...(reservationMode && {
                reservationMode
            }),
            ...(active && {
                active
            }),
            ...(searchText && {
                searchText
            })
        };
        const reservationTemplates = await api.get({
            endpoint: `/reservation-templates?${new URLSearchParams(params)}`
        });
        dispatch(fetchReservationTemplatesSuccess(reservationTemplates));
    }
    catch (e) {
        dispatch(fetchReservationTemplatesFailure());
        return console.error(e);
    }
});
export const fetchReservationTemplatesForOtherPages = createAppAsyncThunk('reservationTemplates/fetchForOtherPages', async (_, _ref10) => {
    let { dispatch } = _ref10;
    try {
        dispatch(fetchReservationTemplatesRequest());
        const reservationTemplates = await api.get({
            endpoint: `/reservation-templates`
        });
        dispatch(fetchReservationTemplatesForOthersSuccess(reservationTemplates));
    }
    catch (e) {
        dispatch(fetchReservationTemplatesFailure());
        return console.error(e);
    }
});
export const fetchAdvancedSettingsForReservationTemplate = createAppAsyncThunk('reservationTemplates/fetchAdvancedSettings', async (templateId, _ref11) => {
    let { dispatch } = _ref11;
    try {
        dispatch(fetchReservationTemplatesRequest());
        const { results = [] } = await api.get({
            endpoint: `/reservation-templates/${templateId}/advanced`
        });
        dispatch(fetchAdvancedSettingsReservationTemplatesSuccess({
            templateId,
            results
        }));
    }
    catch (e) {
        dispatch(fetchReservationTemplatesFailure());
        return console.error(e);
    }
});
export const createReservationTemplate = createAppAsyncThunk('reservationTemplates/createReservationTemplate', async (reservationTemplate, _ref12) => {
    let { dispatch } = _ref12;
    try {
        dispatch(createReservationTemplateRequest());
        const { id, history, maxNumberOfObjects, ...reservationTemplateBody } = reservationTemplate;
        const response = await api.post({
            endpoint: `/reservation-templates`,
            data: reservationTemplateBody
        });
        dispatch(createReservationTemplateSuccess(response));
        dispatch(fetchReservationTemplates());
    }
    catch (e) {
        dispatch(createReservationTemplateFailure(e?.response?.data?.message));
        return console.error(e?.response?.data ? e?.response?.data : e?.response);
    }
});
export const createUnsavedReservationTemplate = templateType => dispatch => {
    const response = {
        ...createEmptyReservationTemplate(),
        documentation: '',
        standardOrganization: undefined,
        templateType
    };
    dispatch(createUnsavedReservationTemplateSuccess(response));
};
export const setSelectedReservationTemplate = (isFirstTemplate, reservationTemplate) => dispatch => {
    dispatch(setReservationTemplate({
        isFirstTemplate,
        template: reservationTemplate
    }));
};
export const copyReservationTemplate = createAppAsyncThunk('reservationTemplates/copyReservationTemplate', async (reservationTemplate, _ref13) => {
    let { dispatch } = _ref13;
    const response = {
        ...reservationTemplate,
        name: reservationTemplate.name + '-copy',
        id: 0
    };
    dispatch(createUnsavedReservationTemplateSuccess(response));
});
export const deleteReservationTemplateItem = createAppAsyncThunk('reservationTemplates/deleteReservationTemplateItem', async (reservationTemplateId, _ref14) => {
    let { dispatch } = _ref14;
    try {
        dispatch(deleteReservationTemplateRequest());
        await api.delete({
            endpoint: `/reservation-templates/${reservationTemplateId}`
        });
        dispatch(deleteRowReservationTemplateSuccess({
            reservationTemplate: {
                id: reservationTemplateId
            }
        }));
    }
    catch (e) {
        dispatch(deleteReservationTemplateFailure());
        return console.error(e);
    }
});
export const updateReservationTemplate = createAppAsyncThunk('reservationTemplates/updateReservationTemplate', async (reservationTemplate, _ref15) => {
    let { dispatch } = _ref15;
    try {
        dispatch(updateReservationTemplateRequest());
        const { id, history, templateType, maxNumberOfObjects, ...reservationTemplateBody } = reservationTemplate;
        const response = await api.patch({
            endpoint: `/reservation-templates/${reservationTemplate.id}`,
            data: reservationTemplateBody
        });
        dispatch(updateReservationTemplateSuccess(response));
    }
    catch (e) {
        dispatch(updateReservationTemplateFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const moveReservations = createAppAsyncThunk('reservationTemplates/moveReservations', async (_ref16, _ref17) => {
    let { moveReservationsRequest, reservationTemplate } = _ref16;
    let { dispatch } = _ref17;
    try {
        dispatch(updateReservationTemplateRequest());
        const { ...MoveReservationsRequestBody } = moveReservationsRequest;
        const response = await api.patch({
            endpoint: `/reservation-templates/${reservationTemplate.id}/move-reservations`,
            data: {
                ...MoveReservationsRequestBody
            }
        });
        dispatch(moveReservationsSuccess(response));
    }
    catch (e) {
        dispatch(updateReservationTemplateFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const moveReservationsToTrash = createAppAsyncThunk('reservationTemplates/moveToTrash', async (reservationTemplate, _ref18) => {
    let { dispatch } = _ref18;
    try {
        dispatch(updateReservationTemplateRequest());
        const response = await api.patch({
            endpoint: `/reservation-templates/${reservationTemplate.id}/move-to-trash`
        });
        dispatch(moveToTrashSuccess(response));
    }
    catch (e) {
        dispatch(updateReservationTemplateFailure());
        return console.error(e.response.data ? e.response.data : e.response);
    }
});
export const checkTemplateSaveDisabled = isFirstDrawer => (_, getState) => {
    const { firstSelectedReservationTemplate, secondSelectedReservationTemplate, map: templateMap } = getState().reservationTemplates;
    if (isFirstDrawer && firstSelectedReservationTemplate && templateMap[firstSelectedReservationTemplate.id]) {
        return checkSavedDisabled(firstSelectedReservationTemplate, templateMap[firstSelectedReservationTemplate.id]);
    }
    if (!isFirstDrawer && secondSelectedReservationTemplate && templateMap[secondSelectedReservationTemplate.id]) {
        return checkSavedDisabled(secondSelectedReservationTemplate, templateMap[secondSelectedReservationTemplate.id]);
    }
    return true;
};
export const getFirstDrawer = () => (_, getState) => getState().reservationTemplates.firstSelectedReservationTemplate;
