import { createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { isArray, isUndefined } from 'lodash';
import { getRedirectUri } from '@timeedit/types/lib/utils/redirectUrls.util';
import { EAppId } from '@timeedit/types';
import { EAppEnv } from '@timeedit/types/lib/enums/appEnv.enum';
import axios from 'axios';
const initialState = {
    hasErrors: false,
    pages: []
};
const slice = createSlice({
    name: 'viewerPages',
    initialState,
    reducers: {
        onLoad: (state, _ref) => {
            let { payload: data } = _ref;
            state.pages = data.pages;
        }
    }
});
export default slice.reducer;
// Actions
export const { onLoad } = slice.actions;
// Selectors
export const viewerPagesSelector = state => state.viewerPages.pages;
const appEnv = process.env.REACT_APP_TE_APP_ENV ?? EAppEnv.PRODUCTION;
// Thunks
export const requestViewerPages = customerSignature => async (dispatch) => {
    const url = getRedirectUri(EAppId.TE_VIEWER, customerSignature, appEnv) + '/pages.json';
    try {
        const response = await axios(url, {});
        dispatch(onLoad({
            pages: createOptions(response.data),
            hasErrors: false
        }));
    }
    catch (e) {
        notification.warning({
            duration: 0,
            placement: 'bottomLeft',
            key: 'requestViewerPages',
            message: 'Failed to load pages from Viewer',
            description: 'URL ' + url + '\n. ' + e
        });
        console.log(e);
    }
};
export function createOptions(json) {
    if (!isArray(json)) {
        return;
    }
    if (json.length === 0) {
        return;
    }
    const options = [];
    for (const entry of json) {
        if (isUndefined(entry.name)) {
            continue;
        }
        if (isUndefined(entry.pages)) {
            continue;
        }
        const path = `${entry.path}`;
        options.push({
            key: path,
            label: `${entry.name}`,
            options: makePageOptions(entry.pages, path)
        });
    }
    return options;
}
function makePageOptions(json, path) {
    if (!isArray(json)) {
        return;
    }
    if (json.length === 0) {
        return;
    }
    const options = [];
    for (const page of json) {
        if (page.type === 'WelcomeController') {
            continue;
        }
        if (page.type === 'SplitController') {
            continue;
        }
        options.push({
            value: `${path}${page.id}`,
            label: `${page.name}`,
            id: `${page.id}`,
            path: `${path}`,
            urlstart: `${page.urlstart}`
        });
    }
    return options;
}
