import { concat, isEmpty, uniq, compact, keyBy } from 'lodash';
export const TEMP_ID = 0;
const convertDataTypesFilter = (ids, allRecords, filterItem, parentIds) => {
    if (!ids)
        return null;
    const result = [];
    ids.forEach(id => {
        const type = allRecords.find(record => record.id === id);
        if (!type)
            return 0;
        const children = convertDataTypesFilter(type.subTypes, allRecords, filterItem, [...(parentIds || []), type.id]);
        const dataConverted = {
            ...type,
            key: type.id,
            title: type.name,
            active: type.active + '',
            parentIds,
            children: children
        };
        // no filterItem
        if (!filterItem) {
            result.push(dataConverted);
        }
        else if (!isEmpty(children)) {
            // not a leaf node
            result.push(dataConverted);
        }
        else if (filterItem && filterItem(type)) {
            // is leaf node (!children)
            result.push(dataConverted);
        }
    });
    return result;
};
export const filterObjectTypesItem = (filter, type) => {
    const { freeTextSearch, reservationTemplate, active } = filter;
    if (reservationTemplate) {
        if (!type?.reservationTemplates || !type.reservationTemplates.includes(reservationTemplate)) {
            return false;
        }
    }
    if (freeTextSearch && !(type.name || '').toLowerCase().includes(freeTextSearch.toLowerCase())) {
        return false;
    }
    if (typeof active === 'boolean' && type.active !== active) {
        return false;
    }
    return true;
};
export const handleCombineObjectTypesWithFilter = (allTypes, filterItem) => {
    const dataConverted = [];
    const listParentIds = new Set([]);
    const listChildrenTypes = new Set([]);
    allTypes.forEach(type => {
        const { subTypes, active, id, name } = type;
        if (subTypes) {
            subTypes.map(i => listChildrenTypes.add(i));
            listParentIds.add(id);
        }
        const children = convertDataTypesFilter(subTypes, allTypes, filterItem, [id]);
        const dataConvertedItem = {
            ...type,
            key: id,
            title: name,
            active: active + '',
            children
        };
        // no filterItem
        if (!filterItem || !isEmpty(children) || filterItem(type)) {
            dataConverted.push(dataConvertedItem);
        }
    });
    const dataFilter = dataConverted.filter(item => !listChildrenTypes.has(item.id));
    return {
        dataFilter,
        listParentIds: Array.from(listParentIds)
    };
};
export const convertDataTypes2Form = data => {
    if (!data)
        return {};
    const { history = [] } = data;
    const { modified = '', modifiedBy = '' } = history[0] || {};
    return {
        name: data.name,
        fields: data.fields,
        description: data.description,
        extId: data.extId,
        id: data.id,
        active: data.active,
        personalData: data.personalData,
        parentFields: data.parentFields,
        modified,
        modifiedBy,
        altDesignations: data.altDesignations,
        allowStandardObjects: data.allowStandardObjects,
        allowAbstractObjects: data.allowAbstractObjects,
        allowVirtualObjects: data.allowVirtualObjects,
        personType: data.personType,
        locationType: data.locationType,
        aliasType: data.aliasType,
        externalOwner: data.externalOwner,
        organizations: data.organizations,
        documentation: data.documentation,
        parentIds: data.parentIds,
        reservationTemplates: data.reservationTemplates,
        advancedSettings: data.advancedSettings || [],
        skipLastReserved: data.skipLastReserved,
        objectCapacityFieldId: data.objectCapacityFieldId,
        objectSizeFieldId: data.objectSizeFieldId
    };
};
export const isNewObjTypeId = id => id === TEMP_ID;
export const createTypesLocal = parent => {
    const parentIds = compact(concat(parent?.parentIds, parent?.id));
    const parentFields = compact(concat(parent?.parentFields, parent?.fields));
    return {
        name: '',
        fields: [],
        description: '',
        extId: null,
        id: TEMP_ID,
        isLocalData: true,
        active: true,
        personalData: false,
        modified: '',
        modifiedBy: '',
        documentation: '',
        reservationTemplates: [],
        allowStandardObjects: true,
        allowAbstractObjects: false,
        allowVirtualObjects: false,
        personType: false,
        locationType: true,
        parentIds,
        aliasType: TEMP_ID,
        externalOwner: '',
        organizations: [],
        subTypes: [],
        parentFields,
        history: [],
        altDesignations: [],
        skipLastReserved: false
    };
};
export const convertDataTypesForm2BodyUpdate = data => {
    return {
        name: data.name,
        fields: data.fields,
        description: data.description,
        extId: data.extId,
        active: data.active,
        personalData: data.personalData,
        allowStandardObjects: data.allowStandardObjects,
        allowAbstractObjects: data.allowAbstractObjects,
        allowVirtualObjects: data.allowVirtualObjects,
        altDesignations: data.altDesignations,
        personType: data.personType,
        locationType: data.locationType,
        aliasType: data.aliasType,
        externalOwner: data.externalOwner,
        organizations: data.organizations,
        documentation: data.documentation,
        reservationTemplates: data.reservationTemplates,
        skipLastReserved: data.skipLastReserved
    };
};
export const convertDataTypesForm2BodyCreate = data => {
    return {
        name: data.name,
        fields: data.fields,
        description: data.description,
        extId: data.extId,
        active: data.active,
        personalData: data.personalData,
        allowStandardObjects: data.allowStandardObjects,
        allowAbstractObjects: data.allowAbstractObjects,
        allowVirtualObjects: data.allowVirtualObjects,
        personType: data.personType,
        locationType: data.locationType,
        aliasType: data.aliasType,
        externalOwner: data.externalOwner,
        organizations: data.organizations,
        reservationTemplates: data.reservationTemplates,
        skipLastReserved: data.skipLastReserved
    };
};
export const ynOptions = [{
        label: 'Yes',
        value: true
    }, {
        label: 'No',
        value: false
    }];
export const getSubTypes = (typeId, typeTree) => {
    return [typeId, ...(typeTree[typeId]?.descendants || [])];
};
export const buildTypeTree = types => {
    const typeTree = {};
    for (const type of types) {
        const typeNode = addTypeNode(typeTree, type.id);
        typeNode.children = type.subTypes || [];
        for (const childId of typeNode.children) {
            const childNode = addTypeNode(typeTree, childId);
            childNode.parents.push(type.id);
            childNode.ancestors = uniq(concat(childNode.ancestors, type.id, typeNode.ancestors));
            addDescendants(typeTree, childNode);
        }
    }
    return typeTree;
};
function addTypeNode(typeTree, typeId) {
    const typeNode = typeTree[typeId] ?? {
        id: typeId,
        children: [],
        parents: [],
        ancestors: [],
        descendants: []
    };
    typeTree[typeId] = typeNode;
    return typeNode;
}
function addDescendants(typeTree, typeNode) {
    typeNode.descendants = uniq(concat(typeNode.descendants, typeNode.children));
    for (const parentId of typeNode.parents) {
        const parentNode = typeTree[parentId];
        parentNode.descendants = uniq(concat(parentNode.descendants, typeNode.descendants));
        addDescendants(typeTree, parentNode);
    }
}
export const createTypeOptions = objectTypes => {
    const objectTypeMap = keyBy(objectTypes, 'id');
    const checkedIds = new Set([]);
    const convertToOptions = type => {
        const { id, name, subTypes: subTypeIds } = type;
        if (checkedIds.has(id)) {
            return undefined;
        }
        checkedIds.add(id);
        const subTypes = [];
        subTypeIds?.forEach(subTypeId => {
            const subType = objectTypeMap[subTypeId];
            if (subType) {
                subTypes.push(subType);
            }
        });
        return {
            value: id.toString(),
            label: name,
            children: subTypeIds && subTypeIds.length > 0 ? compact(subTypes.map(convertToOptions)) : undefined
        };
    };
    return compact(objectTypes.map(convertToOptions));
};
