import { createSlice } from '@reduxjs/toolkit';
import api from '../services/api.service';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { createAppAsyncThunk } from 'slices/utils';
export const initialState = {
    loading: false,
    hasErrors: false,
    settings: {}
};
// Slice prefReviewSettings
const slice = createSlice({
    name: 'appsAllocateSettings',
    initialState,
    reducers: {
        fetchData: state => {
            isLoadingRequest(state);
        },
        fetchDataSuccess: (state, _ref) => {
            let { payload } = _ref;
            state.settings = payload;
            finishedLoadingSuccess(state);
        },
        fetchDataFailure: state => {
            finishedLoadingFailure(state);
        }
    }
});
export default slice.reducer;
// Selectors
export const AllocateSettingsLoading = state => state.appsAllocateSettings.loading;
export const AllocateSettingsSelector = state => state.appsAllocateSettings.settings;
// Actions
export const { fetchData, fetchDataSuccess, fetchDataFailure } = slice.actions;
const ENDPOINT = '/apps/TE_ALLOCATE/allocate-settings';
export const getAllocateSettings = createAppAsyncThunk('appsAllocateSettings/getAllocateSettings', async (_, _ref2) => {
    let { dispatch } = _ref2;
    try {
        dispatch(fetchData());
        const data = await api.get({
            endpoint: ENDPOINT
        });
        dispatch(fetchDataSuccess(data));
    }
    catch (e) {
        dispatch(fetchDataFailure());
        console.error(e);
    }
});
export const updateAllocateSettings = createAppAsyncThunk('appsAllocateSettings/updateAllocateSettings', async (dataBody, _ref3) => {
    let { dispatch } = _ref3;
    try {
        await api.patch({
            endpoint: ENDPOINT,
            data: dataBody,
            successMessage: 'Settings saved'
        });
        dispatch(getAllocateSettings());
    }
    catch (e) {
        dispatch(fetchDataFailure());
        console.error(e);
    }
});
