import ReservationTemplatesPage from '../pages/configuration/reservationTemplates';
import ObjectTypesPage from '../pages/configuration/objectTypes';
import APIKeysPage from '../pages/apiAndServices/apiKeys';
// REWORKED
import GeneralSettings from '../pages/configuration/generalSettings';
import AuthConfigsPage from '../pages/userManagement/authConfigs';
import UsersPage from '../pages/userManagement/users';
import RolePermissionPage from '../pages/userManagement/rolePermissions';
import RolePage from '../pages/userManagement/roles';
import PaddingRulesPage from '../pages/configuration/paddings';
import OrganizationSettingPage from '../pages/userManagement/organizations';
import ImportsPage from '../pages/userManagement/imports';
import SSOLogsPage from '../pages/userManagement/logs';
import ReportingLogsPage from '../pages/userManagement/reportingLogs';
import intl from '../i18n/intl';
import { getVisibility } from '../utils/authentication';
import IntegrationSettingsPage from '../pages/appSettings/integrationSettings';
import FieldRelationsPage from '../pages/configuration/fieldRelations';
import ReportSettingsPage from '../pages/appSettings/reportSettings';
import ReportDataSourceTypes from '../pages/appSettings/reportSettings/dataSourceTypes';
import ReportObjectTypesMapping from '../pages/appSettings/reportSettings/objectTypes';
import PreferencesSettingsPage from '../pages/appSettings/preferencesSettings';
import PreferencesObjectTypesPage from '../pages/appSettings/preferencesSettings/objectTypes';
import PreferencesReviewSettingsPage from '../pages/appSettings/preferencesSettings/ReviewSettings/ReviewSettings';
import PreferencesWeekNamesSettingsPage from '../pages/appSettings/preferencesSettings/weekNames';
import PreferencesRelationsPage from '../pages/appSettings/preferencesSettings/relations';
import AllocateCurrentPeriodPage from '../pages/appSettings/allocateSettings/CurrentPeriod';
import AllocateSweeperPage from '../pages/appSettings/allocateSettings/SweeperPage';
import AllocateViewerPage from '../pages/appSettings/allocateSettings/ViewerPage';
import AllocateReservationPage from '../pages/appSettings/allocateSettings/ReservationPage';
import ExamTypeMappingPage from '../pages/appSettings/examSettings/objectTypes';
import ExamInCorePage from '../pages/appSettings/examSettings/examInCore';
import ExamObjectRelationshipPage from '../pages/appSettings/examSettings/relations';
import ExamReservationSettingsPage from '../pages/appSettings/examSettings/reservationSettings';
import FieldsPage from '../pages/configuration/fields';
import DynamicAvailabilityPage from '../pages/configuration/dynamicAvailability';
import RelationTypePage from '../pages/configuration/relationTypes';
import AvailabilityRelationsPage from '../pages/configuration/availabilityRelations';
import ReservationModes from '../pages/configuration/reservationModes';
import TimeRulesPage from '../pages/configuration/timeRules';
import MembershipPage from '../pages/configuration/memberships';
import MaintenancePage from '../pages/configuration/maintenance';
import SupportInfoPage from '../pages/configuration/systemPreferences/supportInfo';
import PeriodsPage from '../pages/configuration/periods';
import AdvancedPage from '../pages/configuration/systemPreferences/advanced';
import HolidayReservationPage from '../pages/configuration/holidayReservations';
import EmailTemplatesPage from '../pages/configuration/emailTemplates';
import TimeZonesPage from '../pages/configuration/systemPreferences/timeZones';
import ReportTemplatesPage from '../pages/appSettings/reportSettings/reportTemplate';
import ObjectManagerPage from '../pages/objectManager';
import SeatAllocationSettingsPage from '../pages/appSettings/examV3Settings/seatAllocationSettings';
import ExamV3ReservationSettingsPage from '../pages/appSettings/examV3Settings/reservation';
import ExamV3MappingPage from '../pages/appSettings/examV3Settings/objecTypes';
const language = intl.messages;
/**
 * @description - Map of components to be passed to the ui-components
 */
export const componentMap = {
    GeneralSettings,
    ReservationTemplatesPage,
    ObjectTypesPage,
    PaddingRulesPage,
    ImportsPage,
    SSOLogsPage,
    RolePage,
    RolePermissionPage,
    UsersPage,
    AuthConfigsPage,
    APIKeysPage,
    IntegrationSettingsPage,
    FieldRelationsPage,
    PreferencesSettingsPage,
    ReportSettingsPage,
    ReportDataSourceTypes,
    ReportObjectTypesMapping,
    ExamTypeMappingPage,
    ExamInCorePage,
    ExamObjectRelationshipPage,
    PreferencesObjectTypesPage,
    PreferencesReviewSettingsPage,
    PreferencesWeekNamesSettingsPage,
    PreferencesRelationsPage,
    AllocateCurrentPeriodPage,
    AllocateViewerPage,
    AllocateSweeperPage,
    AllocateReservationPage,
    ExamReservationSettingsPage,
    FieldsPage,
    DynamicAvailabilityPage,
    OrganizationSettingPage,
    RelationTypePage,
    AvailabilityRelationsPage,
    ReservationModes,
    TimeRulesPage,
    MembershipPage,
    MaintenancePage,
    SupportInfoPage,
    PeriodsPage,
    AdvancedPage,
    HolidayReservationPage,
    EmailTemplatesPage,
    TimeZonesPage,
    ReportTemplatesPage,
    ReportingLogsPage,
    ObjectManagerPage,
    SeatAllocationSettingsPage,
    ExamV3ReservationSettingsPage,
    ExamV3MappingPage
};
/**
 * Returns a list of routes for the application
 */
export const getRoutes = (authUser, routeFeatureFlag) => {
    console.log('Updating routes!'); // Leaving this here for now to help with debugging
    const { enableNewReportingSettings, enableObjectManager, enableExamV3 } = routeFeatureFlag;
    const routes = [{
            label: `${language.system_settings}`,
            path: '/configuration',
            redirectTo: '/configuration/general-settings',
            displayInTopBar: true,
            visible: getVisibility({
                minimumPermissions: {
                    permissions: ['TE_ADMIN::readSuper', 'TE_ADMIN::manageTimeRules']
                },
                authUser
            }),
            subItems: [{
                    label: `${language.general_settings}`,
                    path: '/configuration/general-settings',
                    component: 'GeneralSettings',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.reservation_templates}`,
                    path: '/configuration/reservation-templates',
                    component: 'ReservationTemplatesPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.reservation_modes}`,
                    path: '/configuration/reservation-modes',
                    component: 'ReservationModes',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.object_types}`,
                    path: '/configuration/object-types',
                    component: 'ObjectTypesPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.fields}`,
                    path: '/configuration/fields',
                    component: 'FieldsPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.availability_relations}`,
                    path: '/configuration/availability-relations',
                    component: 'AvailabilityRelationsPage',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.padding}`,
                    path: '/configuration/padding-rules',
                    statePath: 'paddingRules.map',
                    component: 'PaddingRulesPage',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.field_relations}`,
                    path: '/configuration/field-relations',
                    component: 'FieldRelationsPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.memberships}`,
                    path: '/configuration/memberships',
                    component: 'MembershipPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.relations}`,
                    path: '/configuration/relation-types',
                    component: 'RelationTypePage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }, {
                    label: `${language.dynamic_availability}`,
                    path: '/configuration/dynamic-availability',
                    component: 'DynamicAvailabilityPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.periods}`,
                    path: '/configuration/periods',
                    component: 'PeriodsPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.time_rules}`,
                    path: '/configuration/time-rules',
                    component: 'TimeRulesPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.system_preferences}`,
                    path: '/configuration/system-preferences',
                    redirectTo: '/configuration/support-info',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: `${language.support_info}`,
                            path: '/configuration/support-info',
                            component: 'SupportInfoPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    permissions: ['TE_ADMIN::readSuper']
                                },
                                authUser
                            })
                        }, {
                            label: `${language.email_templates}`,
                            path: '/configuration/email-templates',
                            component: 'EmailTemplatesPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    permissions: ['TE_ADMIN::readSuper']
                                },
                                authUser
                            })
                        }, {
                            label: `${language.time_zones}`,
                            path: '/configuration/time-zones',
                            component: 'TimeZonesPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    permissions: ['TE_ADMIN::readSuper']
                                },
                                authUser
                            })
                        }, {
                            label: `${language.advanced_preferences_title}`,
                            path: '/configuration/advanced',
                            component: 'AdvancedPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    permissions: ['TE_ADMIN::readSuper']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: `${language.holiday_reservations_title}`,
                    path: '/configuration/holiday-reservations',
                    component: 'HolidayReservationPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    })
                }, {
                    label: `${language.maintenance}`,
                    path: '/configuration/maintenance',
                    component: 'MaintenancePage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::readSuper']
                        },
                        authUser
                    })
                }]
        }, {
            label: `${language.organizations_users}`,
            path: '/user-management',
            redirectTo: '/user-management/organizations',
            displayInTopBar: true,
            visible: getVisibility({
                minimumPermissions: {
                    permissions: ['TE_ADMIN::manageUsers', 'TE_ADMIN::manageOrgs', 'TE_ADMIN::manageRoles', 'TE_ADMIN::manageAuthConfigs']
                },
                authUser
            }),
            subItems: [{
                    label: `${language.organizations}`,
                    path: '/user-management/organizations',
                    component: 'OrganizationSettingPage',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::manageUsers', 'TE_ADMIN::manageOrgs']
                        },
                        authUser
                    })
                }, {
                    label: 'Logs',
                    path: '/user-management/logs',
                    redirectTo: '/user-management/sso',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: 'Single Sign-On',
                            path: '/user-management/sso',
                            component: 'SSOLogsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: 'Imports',
                            path: '/user-management/imports',
                            component: 'ImportsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: 'Reporting Logs',
                            path: '/user-management/reporting-logs',
                            component: 'ReportingLogsPage',
                            visible: enableNewReportingSettings && getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: `Roles`,
                    path: '/user-management/roles',
                    component: 'RolePage',
                    statePath: 'roles.map',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::manageUsers', 'TE_ADMIN::manageRoles']
                        },
                        authUser
                    })
                }, {
                    label: 'Role permissions',
                    path: '/user-management/role-permissions',
                    component: 'RolePermissionPage',
                    statePath: 'rolePermissions.map',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::manageUsers', 'TE_ADMIN::manageRoles']
                        },
                        authUser
                    })
                }, {
                    label: `${language.user_manager}`,
                    path: '/user-management/users',
                    component: 'UsersPage',
                    statePath: 'users.map',
                    labelKey: 'username',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::manageUsers']
                        },
                        authUser
                    })
                }, {
                    label: `${language.auth_configs}`,
                    path: '/user-management/auth-configs',
                    component: 'AuthConfigsPage',
                    statePath: 'authConfigs.map',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            permissions: ['TE_ADMIN::manageUsers', 'TE_ADMIN::manageAuthConfigs']
                        },
                        authUser
                    })
                }]
        }, {
            label: `${language.api_services}`,
            path: '/api-services',
            redirectTo: '/api-services/api-keys',
            displayInTopBar: true,
            visible: getVisibility({
                minimumPermissions: {
                    scopes: ['TE_ADMIN::super']
                },
                authUser
            }),
            subItems: [{
                    label: 'API Keys',
                    path: '/api-services/api-keys',
                    component: 'APIKeysPage',
                    statePath: 'apiKeys.map',
                    labelKey: 'name',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::super']
                        },
                        authUser
                    })
                }]
        }, {
            label: `${language.app_settings}`,
            displayInTopBar: true,
            path: '/app-settings',
            visible: getVisibility({
                minimumPermissions: {
                    scopes: ['TE_ADMIN::super']
                },
                authUser
            }),
            dropdown: [{
                    label: `Integration`,
                    displayInTopBar: true,
                    path: '/app-settings/integration',
                    redirectTo: '/app-settings/integration/integration-settings',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::super']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: `Integration Settings`,
                            path: '/app-settings/integration/integration-settings',
                            component: 'IntegrationSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: 'Exam',
                    displayInTopBar: true,
                    path: '/app-settings/exam',
                    redirectTo: '/app-settings/exam/object-types',
                    visible: !enableExamV3 && getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::super']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: 'Object Types',
                            path: '/app-settings/exam/object-types',
                            component: 'ExamTypeMappingPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'Reservation Settings',
                            path: '/app-settings/exam/reservation-settings',
                            component: 'ExamReservationSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'TE Exam in TE Core',
                            path: '/app-settings/exam/exam-in-core',
                            component: 'ExamInCorePage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'Object Relationship',
                            path: '/app-settings/exam/object-relationship',
                            component: 'ExamObjectRelationshipPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: 'Exam',
                    displayInTopBar: true,
                    path: '/app-settings/exam-management',
                    redirectTo: '/app-settings/exam-management/object-types',
                    visible: enableExamV3 && getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: `${language.exam_v3_object_type_settings_sub_title}`,
                            path: '/app-settings/exam-management/object-types',
                            component: 'ExamV3MappingPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: `${language.exam_v3_reservation_settings_sub_title}`,
                            path: '/app-settings/exam-management/reservation-setting',
                            component: 'ExamV3ReservationSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: `${language.seat_management_allocation_mode_sub_title}`,
                            path: '/app-settings/exam-management/seat-allocation-settings',
                            component: 'SeatAllocationSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: `Reporting`,
                    displayInTopBar: true,
                    path: '/app-settings/reporting',
                    redirectTo: `/app-settings/reporting/${enableNewReportingSettings ? 'report-templates' : 'object-types'}`,
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::super']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: `Object Types`,
                            path: '/app-settings/reporting/object-types',
                            component: 'ReportObjectTypesMapping',
                            visible: !enableNewReportingSettings && getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: `Data Sources`,
                            path: '/app-settings/reporting/data-sources',
                            component: 'ReportDataSourceTypes',
                            visible: !enableNewReportingSettings && getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: `Report Templates`,
                            path: '/app-settings/reporting/report-templates',
                            component: 'ReportTemplatesPage',
                            visible: enableNewReportingSettings
                        }]
                }, {
                    label: `Preferences`,
                    displayInTopBar: true,
                    path: '/app-settings/preferences',
                    redirectTo: '/app-settings/preferences/object-types',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::super']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: 'Object Types',
                            path: '/app-settings/preferences/object-types',
                            component: 'PreferencesObjectTypesPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'Week Name',
                            path: '/app-settings/preferences/week-name',
                            component: 'PreferencesWeekNamesSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'Review Settings',
                            path: '/app-settings/preferences/review-settings',
                            component: 'PreferencesReviewSettingsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }, {
                            label: 'Relations',
                            path: '/app-settings/preferences/relations',
                            component: 'PreferencesRelationsPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::super']
                                },
                                authUser
                            })
                        }]
                }, {
                    label: `Allocate`,
                    displayInTopBar: true,
                    path: '/app-settings/allocate',
                    redirectTo: '/app-settings/allocate/period',
                    visible: getVisibility({
                        minimumPermissions: {
                            scopes: ['TE_ADMIN::admin']
                        },
                        authUser
                    }),
                    subItems: [{
                            label: language.manage_period_page,
                            path: '/app-settings/allocate/period',
                            component: 'AllocateCurrentPeriodPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: language.manage_viewer_page,
                            path: '/app-settings/allocate/viewer',
                            component: 'AllocateViewerPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: language.manage_reservation_page,
                            path: '/app-settings/allocate/reservation',
                            component: 'AllocateReservationPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }, {
                            label: language.manage_sweeper_page,
                            path: '/app-settings/allocate/sweeper',
                            component: 'AllocateSweeperPage',
                            visible: getVisibility({
                                minimumPermissions: {
                                    scopes: ['TE_ADMIN::admin']
                                },
                                authUser
                            })
                        }]
                }]
        }, {
            label: 'Object Manager',
            path: '/object-manager',
            visible: enableObjectManager && getVisibility({
                minimumPermissions: {
                    scopes: ['TE_ADMIN::super']
                },
                authUser
            }),
            component: 'ObjectManagerPage'
        }];
    const sanitizeItems = items => items.filter(subItem => subItem.visible);
    const sanitizedRoutes = routes.filter(item => item.visible) // Filter out non-visible routes first
        .map(item => {
        const sanitizedItem = {
            ...item
        }; // Clone the item to avoid mutating the original object
        if (item.subItems?.length) {
            sanitizedItem.subItems = sanitizeItems(item.subItems);
        }
        if (item.dropdown?.length) {
            sanitizedItem.dropdown = sanitizeItems(item.dropdown);
        }
        return sanitizedItem;
    });
    return sanitizedRoutes;
};
/**
 * @description Creates a flat version of the routes array
 * @param routes {array} - array of routes
 */
export const getRoutesFlat = routes => {
    const routesFlat = [];
    routes.forEach(route => {
        routesFlat.push(route);
        if (route.subItems) {
            route.subItems.forEach(subItem => {
                routesFlat.push(subItem);
            });
        }
        if (route.dropdown) {
            route.dropdown.forEach(dropdownItem => {
                routesFlat.push(dropdownItem);
            });
        }
    });
    return routesFlat;
};
